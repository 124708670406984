/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable quotes */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Transition } from '@headlessui/react';
import Button from 'elements/Button';
import BrandIcon from 'parts/BrandIcon';
import { useHistory } from 'react-router-dom';

export default function Header(props) {
  const { location } = props;
  const history = useHistory();
  const [isCollapse, setIsCollapse] = useState(false);
  const currentPath = location.pathname;

  const handleNavigation = (path) => {
    history.push(path);
  };

  return (
    <header className="header">
      <Fade>
        <div className="flex justify-between px-4 lg:px-0">
          <BrandIcon />

          <button
            type="button"
            className="block text-theme-yellow lg:hidden focus:outline-none"
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <svg
              className="w-8 h-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                className={`${isCollapse ? 'hidden' : 'block'}`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
              <path
                className={`${!isCollapse ? 'hidden' : 'block'}`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <ul className="hidden text-theme-yellow tracking-widest items-center lg:flex flex-row mt-0">
          <li>
            <Button
              className={`${
                currentPath === '/' ? 'active-link' : ''
              } text-lg px-5 no-underline hover:underline`}
              type="link"
              href="/"
            >
              Home
            </Button>
          </li>
          <li className="py-2 lg:py-0">
            <Button
              className={`${
                currentPath === '/team' ? 'active-link' : ''
              } text-lg px-5 no-underline hover:underline`}
              type="link"
              href="/team"
            >
              Team
            </Button>
          </li>
          <li className="py-2 lg:py-0">
            <Button
              className={`${
                currentPath === '/project' ? 'active-link' : ''
              } text-lg px-5 no-underline hover:underline`}
              type="link"
              href="/project"
            >
              Project
            </Button>
          </li>
          <li>
            <Button
              className="text-lg mx-auto ml-3 px-6 py-2 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200"
              type="link"
              href="/discuss-project"
            >
              Discuss Project
            </Button>
          </li>
        </ul>
      </Fade>

      <Transition
        show={isCollapse}
        enter="transition-opacity duration-400"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-400"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Fade>
          <ul className="z-50 flex flex-col text-theme-yellow tracking-widest my-6 absolute bg-container w-full border-b-2 border-gray-300 lg:hidden">
            <li
              className="bg-container"
              style={{ borderBottom: '1px solid #37383805' }}
            >
              <div
                className="menuTab"
                onClick={() => handleNavigation('/')}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && handleNavigation('/')}
              >
                <span className="menuText">Home</span>
              </div>
            </li>
            <li
              className="bg-container"
              style={{ borderBottom: '1px solid #37383805' }}
            >
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className="menuTab"
                onClick={() => handleNavigation('/team')}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && handleNavigation('/team')}
              >
                <span className="menuText">Team</span>
              </div>
            </li>
            <li
              className="bg-container"
              style={{ borderBottom: '1px solid #37383805' }}
            >
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className="menuTab"
                onClick={() => handleNavigation('/project')}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && handleNavigation('/project')}
              >
                <span className="menuText">Project</span>
              </div>
            </li>
            <li className="mx-auto my-9 bg-container">
              <Button
                className="mx-auto px-5 py-2 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200"
                type="link"
                href="/discuss-project"
              >
                Discuss Project
              </Button>
            </li>
          </ul>
        </Fade>
      </Transition>
    </header>
  );
}

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
