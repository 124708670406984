import React from 'react';

import Button from 'elements/Button';
import INSTA from '../assets/images/instagram.svg';
import LINKEDIN from '../assets/images/linkedin.svg';
import GITHUB from '../assets/images/icons8-github.svg';

export default function Footer() {
  return (
    <div
      className="border-t pb-6 pt-6"
      style={{
        background: '#333b4d75',
        borderTop: '1px solid #7c7d8052',
        paddingBlock: '50px',
      }}
    >
      <div
        className="container flex-col mx-auto"
        style={{ background: 'transparent', width: '90%' }}
      >
        <div
          className="flex flex-col sm:flex-row justify-center"
          style={{ background: 'transparent' }}
        >
          <div
            className="w-1/3 flex-col ml-16 mr-8 footerDiv"
            style={{ background: 'transparent' }}
          >
            <p
              className="text-theme-yellow text-4xl"
              style={{ background: 'transparent' }}
            >
              Vesperon Tech
            </p>
            <p
              className="w-full text-lg text-gray-400 font-light"
              style={{ background: 'transparent' }}
            >
              Growing Your Business
              <br />
              Is Our Calling
            </p>
          </div>
          <div
            className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5"
            style={{ background: 'transparent' }}
          >
            <h1
              className="text-lg text-theme-yellow pt-4 pb-2"
              style={{ background: 'transparent' }}
            >
              Office
            </h1>
            <p
              className="text-lg text-gray-400 font-light"
              style={{ background: 'transparent' }}
            >
              vesperontech@gmail.com
            </p>
            <p
              className="text-lg text-gray-400 font-light"
              style={{ background: 'transparent' }}
            >
              Bangalore, India
            </p>
          </div>
          <div
            className="w-1/3 ml-16 sm:ml-0 mt-0"
            style={{ background: 'transparent' }}
          >
            <h1
              className="text-lg text-theme-yellow pt-4 pb-2"
              style={{ background: 'transparent' }}
            >
              Social
            </h1>
            <div
              className="flex justify-start gap-4"
              style={{ background: 'transparent' }}
            >
              <Button
                href="https://www.instagram.com/vesperontech?igsh=bWFhMDZjb3doajF1"
                type="link"
                target="_blank"
                className="flex text-lg text-gray-400 font-light hover:underline cursor-pointer"
                isExternal
                style={{ background: 'transparent' }}
              >
                <img
                  src={INSTA}
                  alt="Instagram"
                  style={{ background: 'transparent' }}
                />
              </Button>
              <Button
                href="https://www.linkedin.com/company/vesperontech/"
                type="link"
                target="_blank"
                className="flex text-lg text-gray-400 font-light hover:underline cursor-pointer"
                isExternal
                style={{ background: 'transparent' }}
              >
                <img
                  src={LINKEDIN}
                  alt="LinkedIn"
                  style={{ background: 'transparent' }}
                />
              </Button>
              <Button
                href="https://github.com/axium-corporation"
                type="link"
                target="_blank"
                className="flex text-lg text-gray-400 font-light hover:underline"
                isExternal
                style={{ background: 'transparent' }}
              >
                <img
                  src={GITHUB}
                  alt="Github"
                  style={{ background: 'transparent' }}
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
