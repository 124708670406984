/* eslint-disable quotes */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LandingPage from "pages/LandingPage";
import ProjectPage from 'pages/ProjectPage';
import ProjectDetailPage from 'pages/ProjectDetailPage';
import TeamPage from 'pages/TeamPage';
import DiscussProjectPage from 'pages/DiscussProjectPage';
import NotFoundPage from 'pages/NotFoundPage';
// Import other components as needed

import "assets/css/styles.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/project" component={ProjectPage} />
        <Route exact path="/project/:id" component={ProjectDetailPage} />
        <Route exact path="/team" component={TeamPage} />
        <Route exact path="/discuss-project" component={DiscussProjectPage} />
        <Route path="" component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
